// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-checkered-slider .flexslider .slides').each(function(){
		if($(this).children().length > 1){
			$(this).slick({
				nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-angle-right"></i>Next Slide</button>',
				prevArrow: '<button type="button" class="slick-prev"><i class="fa-solid fa-angle-left"></i>Previous Slide</button>',
				responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						dots: true,
					}
			  
				}]
			});
		}
	});


	//Slick slider for whole rows on small screens
	if($( window ).width() < 769){
		$('.checkerboard-outer-wrapper.style-overlap-center.slider').slick({
			arrows: false,
			dots: true,
		});
	}
	$(window).on('resize', function(){
		var win = $(this); //this = window
		var existingSlider = $('.checkerboard-outer-wrapper .slick-track');
		if (win.width() >= 769 && existingSlider) {
			$('.checkerboard-outer-wrapper.style-overlap-center.slider').slick('unslick');
		}else{
			$('.checkerboard-outer-wrapper.style-overlap-center.slider').slick({
				arrows: false,
				dots: true,
			});
		}
	});
}); /* end of as page load scripts */